<div class="modal fade show"
     tabindex="-1"
     (click)="closeModal($event)">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content"
         (click)="$event.stopPropagation()">
      <div class="modal-header">
        <h5 class="modal-title">{{modalTitle}}</h5>
        <button type="button"
                class="close"
                (click)="closeModal($event)">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer">
        <button type="button"
                class="btn btn-secondary"
                (click)="closeModal($event)">Close</button>
        <button type="button"
                class="btn btn-primary"
                (click)="saveModal($event)">Save changes</button>
      </div>
    </div>
  </div>
</div>

<div class="modal-backdrop fade show"></div>