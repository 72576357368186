import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bd-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class CollectionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
