<div class="d-flex flex-column flex-xl-row">
  <aside
         class="d-flex justify-content-center align-items-center flex-grow-0 flex-xl-column justify-content-xl-start left-sidebar">
    <a class="mx-auto d-none d-xl-block app-logo"
       routerLink=""></a>

    <nav class="d-flex flex-xl-column align-items-center">
      <button *ngFor="let menuItem of menuItems"
              class="btn btn-menu btn-icon icon-{{menuItem.className}}"
              [routerLink]="menuItem.url"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">{{menuItem.title}}</button>
    </nav>

    <bd-footer class="d-none d-xl-flex mt-auto"></bd-footer>
  </aside>

  <main class="flex-grow-1 d-flex">
    <router-outlet></router-outlet>
  </main>
</div>
